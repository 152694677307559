import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  addSailToCart, 
  deleteSailFromCart,
  updateSailInCart,
  setShippingInformation,
  setCart,
  clearCart,
  clearStripePaymentIntent,
  applySelfInstallDiscount,
  removeSelfInstallDiscount,
  setCouponCode,
  setCouponCodeResult,
  setCouponCodeFetching,
  setCouponCodeError,
  setShippingAddress,
  setBillingAddress,
  setSelectedCouponCode,
  setSelectedFreeWarrantyCouponCode,
  setSelectedNoInstallationKitCouponCode,
  clearSelectedCouponCode,
  clearSelectedFreeWarrantyCouponCode,
  clearSelectedNoInstallationKitCouponCode,
  clearExtendedWarranty,
  addExtendedWarranty,
  showWhatHappensNextDialog,
  hideWhatHappensNextDialog,
} from './actions'
import { CartContext } from './context'
import { SELF_INSTALL_DISCOUNT } from './../../components/OrderContentBlock/utilNode.cjs'
import { getShadeSailItems } from './../../components/OrderContentBlock/util'
import { useInitializeCart } from '../../hooks/cart/useInitializeCart'
import { useDeleteCartOption } from '../../hooks/cart/useDeleteCartOption'
import { useAddOrUpdateCartOption } from '../../hooks/cart/useAddOrUpdateCartOption'
import { useClearCart } from '../../hooks/cart/useClearCart'

const { useReducer, useRef, useEffect} = React

const CartLogic = props => {
  const { children } = props
  const addOrUpdateCartOption = useAddOrUpdateCartOption()
  const [reducerState, dispatch] = useReducer(reducer, defaultState)
  const fetchCouponCodeRef = useRef({timeout: 0}).current

  const { initializeCart } = useInitializeCart(dispatch)
  const deleteCartOption = useDeleteCartOption()
  const clearBackendCart = useClearCart()

  useEffect(() => {
    (async () => {
      await initializeCart()
    })()
  }, [])

  const fetchCouponCode = (couponCode) => {
    
    dispatch(setCouponCodeError(null))
    dispatch(setCouponCodeResult(null))

    if (!couponCode)
      return
    dispatch(setCouponCodeFetching(true))

    fetch(
      process.env.GATSBY_STRIPE_API + '/coupon-code/' + couponCode
    )
      .then(response => {
        return response.json()
          .then(async result => {
            dispatch(setCouponCodeFetching(false))
            if (response.status != 200){
              throw new Error(result.message || 'Unable to fetch coupon code information.')
            }

            if (result['couponCode']?.['coupon']?.['type'] === 'FREEWARRANTY'){
              dispatch(setSelectedFreeWarrantyCouponCode(result))
              await addOrUpdateCartOption('freeWarranty', result['couponCode'])
              return
            }
            if (result['couponCode']?.['coupon']?.['type'] === 'NOINSTALLATIONKIT'){
              dispatch(setSelectedNoInstallationKitCouponCode(result))
              await addOrUpdateCartOption('noInstallationKit', result['couponCode'])
              return
            }

            dispatch(setCouponCodeResult(result))

            if (result.couponCode){
              dispatch(setSelectedCouponCode(result))
              await addOrUpdateCartOption('couponCode', result['couponCode'])
              
            }

          })
      })
      .catch(error => {
        console.error(error)
        dispatch(setCouponCodeFetching(false))
        dispatch(setCouponCodeError(error))
        throw error
      })
  }

  var couponDiscount = 0
  var couponPercentDiscount = 0
  var coupon = reducerState.selectedCouponCodeResult?.couponCode?.coupon
  if (coupon){
    if (coupon.amount_off){
      couponDiscount = coupon.amount_off
    } else if (coupon.percent_off) {
      couponPercentDiscount = coupon.percent_off
    }
  }

  return <CartContext.Provider
    value={{
      addSailToCart: (sail) => {
        dispatch(addSailToCart(sail))
        if (typeof window.fbq !== 'undefined'){
          
          const items = getShadeSailItems(sail)

          var total = items.reduce((pre, cur) => {
            return pre + (cur.price * (1 - SELF_INSTALL_DISCOUNT))
          }, 0)
          total = Math.round(total)

          window.fbq('track', 'AddToCart', {
            currency: 'AUD',
            // This is the value without GST
            value: total / 100,
          })
        }
      },
      deleteSailFromCart: sail => {
        dispatch(deleteSailFromCart(sail))
      },
      updateSailInCart: (sail) => {
        dispatch(updateSailInCart(sail))
      },
      setShippingInformation: shippingInfo => {
        dispatch(setShippingInformation(shippingInfo))
      },
      setCart: (sails) => {
        dispatch(setCart(sails))
      },
      clearCart: async () => {
        dispatch(clearCart())
        // Clear cart on the backend side
        await clearBackendCart()
      },
      clearStripePaymentIntent: () => {
        dispatch(clearStripePaymentIntent())
      },
      applySelfInstallDiscount: () => dispatch(applySelfInstallDiscount()),
      removeSelfInstallDiscount: () => dispatch(removeSelfInstallDiscount()),
      dispatch,
      reducerState,

      showWhatHappensNextDialog: () => dispatch(showWhatHappensNextDialog()),
      hideWhatHappensNextDialog: () => dispatch(hideWhatHappensNextDialog()),

      updateCouponCode: (couponCode) => {
        dispatch(setCouponCode(couponCode))
        clearTimeout(fetchCouponCodeRef.timeout)
        fetchCouponCodeRef.timeout = setTimeout(() => fetchCouponCode(couponCode), 200)
      },
      // TODO Should probably remove these two and infer from the result
      couponDiscount,
      couponPercentDiscount,
      setShippingAddress: address => dispatch(setShippingAddress(address)),
      setBillingAddress: address => dispatch(setBillingAddress(address)),
      clearSelectedCouponCode: async () => {
        dispatch(clearSelectedCouponCode())
        await deleteCartOption('couponCode')
      },
      clearSelectedFreeWarrantyCouponCode: async () => {
        dispatch(clearSelectedFreeWarrantyCouponCode())
        await deleteCartOption('freeWarranty')
      },
      clearSelectedNoInstallationKitCouponCode: async () => {
        dispatch(clearSelectedNoInstallationKitCouponCode())
        await deleteCartOption('noInstallationKit')
      },
      clearExtendedWarranty: async () => {
        dispatch(clearExtendedWarranty())
        await addOrUpdateCartOption('extendedWarranty', {isActive: false})
      },
      addExtendedWarranty: async () => {
        dispatch(addExtendedWarranty())
        await addOrUpdateCartOption('extendedWarranty', {isActive: true})
      },
    }}
  >
    {children}
  </CartContext.Provider>
}

export default CartLogic
